import { Component, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
import { GET_PROFILE_MEMBER, EDIT_MEMBER, GET_ROYALTY_SETTING_BY_ID, GET_HISTORY_MEMBER, GET_HISTORY_MEMBER_BY_DATE } from "../../../../api/account"
import vSelect from 'vue-select';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'
import moment from 'moment';

Vue.use(VModal, { componentName: 'modal' })
Vue.use(VueCookies)

import 'vue-select/dist/vue-select.css';

@Component({
    components: {
        vSelect
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        date(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        sCurrency(value: any) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
})

default class EditMember extends Vue {
    $axios: any;
    private selected = "";
    private navShow = true
    private role = ""
    public updateDate = "";
    private profile: any = {}
    private historyData: any = {}
    private myRate = 0
    private yourRate = 0
    private ipUser = "0.0.0.0"
    private userId = ""
    private cookieData: any = Vue.$cookies.get("login")
    private options: { text: string; value: string }[] = []
    private displayShow = true
    private dataMember: any = {
        username: '',
        name: '',
        email: '',
        phone: '',
        referrer: '',
        remark: '',
        royaltySetting: [],
    }
    private password = {
        default: false,
        confirm: false
    }
    private limitList = [
        { name: 'Limit A', min: 40, max: 200, value: 1, check: false },
        { name: 'Limit B', min: 200, max: 1000, value: 2, check: false },
        { name: 'Limit C', min: 2000, max: 10000, value: 3, check: false },
        { name: 'Limit D', min: 10000, max: 50000, value: 4, check: false },
        { name: 'Limit E', min: 50000, max: 100000, value: 5, check: false }
    ]
    private limitListHistory = [
        { name: 'Limit A', min: 40, max: 200, value: 1, check: false },
        { name: 'Limit B', min: 200, max: 1000, value: 2, check: false },
        { name: 'Limit C', min: 2000, max: 10000, value: 3, check: false },
        { name: 'Limit D', min: 10000, max: 50000, value: 4, check: false },
        { name: 'Limit E', min: 50000, max: 100000, value: 5, check: false }
    ]
    private royaltySetting: any = []
    private historyLists = []
    private historyLoading = true;
    private model: Model;
    private numberArray: any = []
    private currencyArray: any = []
    constructor() {
        super();
        this.model = new Model();
    }
    async mounted() {
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        await this.getRoyaltySetting() // find my rate
        this.userId = this.$route.query.id.toString()
        await this.getProfile(this.userId)
        await this.initData()
        await this.setRate()
        this.addRouteName()
        loader.hide()
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public async initData() {
        const currencyUse = process.env.VUE_APP_CURRENCY_USE
        this.currencyArray.push({ label: `${currencyUse}`, value: `${currencyUse}` });
        this.dataMember['parentName'] = this.cookieData.realUsername
        this.dataMember['parentId'] = this.cookieData.realUsername
    }

    public async setRate() {
        for (const value of this.royaltySetting.products) {
            if (value.productId == 'POKER') {
                this.myRate = value.rate[process.env.VUE_APP_CURRENCY_USE]
            }
        }

        for (let i = 0; i <= this.myRate; i++) {
            this.numberArray.push({ label: `${i}%`, value: `${i}` });
        }
        this.dataMember['myPercentage'] = this.numberArray[this.myRate - this.yourRate]
        this.dataMember['yourPercentage'] = this.numberArray[this.yourRate]
    }

    public async getRoyaltySetting() {
        this.royaltySetting = await GET_ROYALTY_SETTING_BY_ID(this.cookieData.realUsername)
        this.royaltySetting = this.royaltySetting[0].royaltySetting
    }

    public toggleShowPassword(name: string) {
        if (name == 'default') {
            this.password.default = !this.password.default;
        }
        if (name == 'confirm') {
            this.password.confirm = !this.password.confirm;
        }
    }

    private async getProfile(username: string) {
        username = `${this.cookieData.realUsername}@${username}`
        const result = await GET_PROFILE_MEMBER(username)
        this.role = this.cookieData.roles[0]
        this.profile = result[0]
        this.dataMember['username'] = this.profile.username
        this.dataMember['name'] = this.profile.name
        this.dataMember['lock'] = this.profile.lock
        this.dataMember['suspend'] = this.profile.suspend
        this.dataMember['email'] = this.profile.information.contact.email
        this.dataMember['phone'] = this.profile.information.contact.phone
        this.dataMember['referrer'] = this.profile.information.other.referer
        this.dataMember['remark'] = this.profile.information.other.remark
        this.dataMember['creditLimit'] = this.profile.creditLimit
        this.dataMember['currency'] = process.env.VUE_APP_CURRENCY_USE

        for (const value of this.profile.royaltySetting.products) {
            if (value.productId == 'POKER') {
                this.dataMember['limit'] = value.limit
                this.dataMember['myLimit'] = this.royaltySetting.products[0].limit
                this.yourRate = value.rate[process.env.VUE_APP_CURRENCY_USE]
                this.limitList[value.limit - 1].check = true
            }
        }

        const rawData = await GET_HISTORY_MEMBER(username.toString())
        this.historyLists = rawData.data
    }

    public async getHistoryItem(date: string) {
        this.historyLoading = false
        const id = this.$route.query.id
        const rawData = await GET_HISTORY_MEMBER_BY_DATE(id.toString(), date)
        const historyData = rawData.data[0]
        this.historyData['username'] = historyData.username
        this.historyData['name'] = historyData.name
        this.historyData['lock'] = historyData.lock
        this.historyData['suspend'] = historyData.suspend
        this.historyData['email'] = historyData.information.contact.email
        this.historyData['phone'] = historyData.information.contact.phone
        this.historyData['referrer'] = historyData.information.other.referer
        this.historyData['remark'] = historyData.information.other.remark
        this.historyData['creditLimit'] = historyData.creditLimit
        this.historyData['currency'] = process.env.VUE_APP_CURRENCY_USE
        this.historyData['typeAgent'] = historyData.royaltySetting.typeAgent
        this.historyData['typeAgentAPI'] = historyData.royaltySetting.typeAgentAPI
        this.historyData['typeBusiness'] = historyData.royaltySetting.typeBusiness

        for (const value of historyData.royaltySetting.products) {
            if (value.productId == 'POKER') {
                this.limitListHistory = [
                    { name: 'Limit A', min: 40, max: 200, value: 1, check: false },
                    { name: 'Limit B', min: 200, max: 1000, value: 2, check: false },
                    { name: 'Limit C', min: 2000, max: 10000, value: 3, check: false },
                    { name: 'Limit D', min: 10000, max: 50000, value: 4, check: false },
                    { name: 'Limit E', min: 50000, max: 100000, value: 5, check: false }
                ]
                this.historyData['limit'] = value.limit
                this.historyData['yourRate'] = value.rate[process.env.VUE_APP_CURRENCY_USE]
                this.limitListHistory[value.limit - 1].check = true
            }
        }

        this.$modal.show('modalHistory');
        this.historyLoading = true
    }

    public onlyNumber(event: any) {
        const keyCode = (event.keyCode ? event.keyCode : event.which);
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
            event.preventDefault();
        }
    }

    public clsAlphaNoOnly(event: any) {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public clsAlphaTHNoOnly(event: any) {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[ก-๏a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public formatNumber(value) {
        return value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    private hide() {
        this.$modal.hide('modalHistory');
    }

    public async switchPercentage(event, myPercentage, yourPercentage) {
        if (event == 'myPercentage') {
            this.dataMember['myPercentage'] = myPercentage
            this.dataMember['yourPercentage'] = this.numberArray[this.myRate - myPercentage.value]
        } else {
            this.dataMember['myPercentage'] = this.numberArray[this.myRate - yourPercentage.value]
            this.dataMember['yourPercentage'] = yourPercentage
        }
    }


    public changeInputValue(key: string, value: string) {
        this.displayShow = false
        this.dataMember[key] = value
        if (key == 'myPercentage' || key == 'yourPercentage') {
            this.switchPercentage(key, this.dataMember['myPercentage'], this.dataMember['yourPercentage'])
        }
        this.displayShow = true
    }


    public sendForm(event: any) {
        event.preventDefault();
        this.sendCreateMember()
    }

    public async sendCreateMember() {
        if (this.dataMember.password) {
            if (this.dataMember.password.length < 6) {
                this.$swal({
                    text: this.$t('MANAGEMENT.PASSWORD_CHECK').toString(),
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
                return;
            }
            if (this.dataMember.password != this.dataMember.confirmPassword) {
                this.$swal({
                    text: this.$t('MANAGEMENT.PASSWORD_BE_SAME').toString(),
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
                return;
            }
        }

        this.$swal({
            title: `${this.$t('MANAGEMENT.WANT_EDIT_DATA').toString()}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#FF0000',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const resultEditMember = await EDIT_MEMBER(this.dataMember)
                if (resultEditMember.success) {
                    this.$swal({
                        text: `${this.$t('MANAGEMENT.EDIT_MEMBER_SUCCESS').toString()}`,
                        icon: "success",
                        timer: 3000,
                        showConfirmButton: false,
                        timerProgressBar: true,
                        onClose: () => {
                            window.location.replace('/agent/agent-member-list');
                        }
                    });
                } else {
                    this.$swal({
                        text: resultEditMember.error.message,
                        icon: "error",
                        confirmButtonColor: '#CFA137',
                        confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                    });
                }
            }
        })

    }



}
export default EditMember